<template>
  <div>
    <Loading v-if="isLoading" loading-message="載入中..." :background-color="'#ffffff'"/>

    <template v-if="form.status == 'published' && isInValidTimeRange && deepGet(form, 'config.welcome_page.enable', false)">
      <img
        class="banner-image w-100"
        v-if="deepGet(form, 'config.welcome_page.banner_image')"
        :src="deepGet(form, 'config.welcome_page.banner_image')"
      />

      <div class="page">
        <div
          v-if="deepGet(form, 'config.welcome_page.form_title')"
          class="page__title"
        >
          {{ deepGet(form, 'config.welcome_page.form_title') }}
        </div>
        <div v-if="deepGet(form, 'config.welcome_page.form_desc')" class="page__desc">
          {{ deepGet(form, 'config.welcome_page.form_desc') }}
        </div>
        <div
          v-if="deepGet(form, 'config.welcome_page.content')"
          v-html="deepGet(form, 'config.welcome_page.content')"
        ></div>
      </div>

      <div class="page__button s-space-y-4">
        <SharedButton class="s-btn-bg-primary" @click="goToContent">
          {{ deepGet(form, 'config.welcome_page.button_text') }}
        </SharedButton>
      </div>
    </template>
    <template v-else-if="form.status == 'draft' || form.status == 'closed'">
      <div class="page">
        <div class="text-center">找不到此活動</div>
      </div>
    </template>
    <template v-else-if="false == hasStarted">
      <div class="page">
        <div class="text-center">此活動尚未開始</div>
      </div>
    </template>
    <template v-else-if="hasEnded">
      <div class="page">
        <div class="text-center">此活動已結束</div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  isAfter,
  set,
  getHours,
  getMinutes,
} from "date-fns";
import formApi from "@/apis/liff/v2/form";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import Loading from "@/components/Page/Liff/Shared/Loading";
import deepGet from "@/utils/deepGet";
import { parseFormSubjects } from "@/utils/DynamicFormUtils";

export default {
  components: {
    SharedButton,
    Loading,
  },
  data() {
    return {
      inputForm: {},
      subjects: [],
      form: {},
      isLoading: true,
      startedWriting: false,
    };
  },
  computed: {
    hasStarted() {
      const today = set(new Date(), {
        hours: String(getHours(new Date())).padStart(2, "0"),
        minutes: String(getMinutes(new Date())).padStart(2, "0"),
      });

      const startDate = set(new Date(this.form.start_at), {
        hours: String(getHours(new Date(this.form.start_at))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(this.form.start_at))).padStart(2, "0"),
      });

      return isAfter(today, startDate);
    },
    hasEnded() {
      const today = set(new Date(), {
        hours: String(getHours(new Date())).padStart(2, "0"),
        minutes: String(getMinutes(new Date())).padStart(2, "0"),
      });

      const endDate = set(new Date(this.form.end_at), {
        hours: String(getHours(new Date(this.form.end_at))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(this.form.end_at))).padStart(2, "0"),
      });

      return isAfter(today, endDate);
    },
    isInValidTimeRange() {
      return this.hasStarted && false == this.hasEnded;
    },
  },
  watch: {
    inputForm: {
      handler: function (val) {
        console.log("form", val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.fetchForm();
  },
  methods: {
    deepGet,
    goToContent() {
      this.$router.push({
        name: "LiffFormContent",
      });
    },
    async fetchForm() {
      try {
        const response = await formApi.getForm(
          this.$route.params.form_id,
          {}
        );
        this.form = response.data.data;
        this.subjects = parseFormSubjects(this.form);
      } catch (error) {
        if (error.response.status === 404) {
          this.$swal("錯誤", "找不到此問券", "error");
        } else {
          console.error(error);
          this.$swal("錯誤", "", "error");
        }
      } finally {
        const welcomePageIsEnabled = this.deepGet(this.form, 'config.welcome_page.enable', false)
        if (welcomePageIsEnabled) {
          this.isLoading = false;
        } else if (false == this.isInValidTimeRange || this.form.status !== 'published') {
          // stay in this page to see invalid time range message
          this.isLoading = false;
        } else {
          this.goToContent()
        }
      }
    },
  },
};
</script>

<style lang="scss">
.form-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  margin-top: 12px;
  margin-bottom: 12px;
}
</style>
